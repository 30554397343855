.portalTable tbody tr td {
  font-size: 12px;
}

.portalTable thead th {
  font-size: 12px;
}

.btn:focus {
  box-shadow: none !important;
}

.buttonColumn {
  min-width: 42px;
}

.view-job {
  width: 40px;
  text-align: center;
}

.sendResetEmailBtnUserTable {
  width:200px;
}

.center-icon {
  text-align: center;
  vertical-align: middle;
}