/* @extend display-flex; */

display-flex,
.form-flex,
.form-date-group,
.steps ul,
.title,
.title .step-number,
.actions ul li a,
.actions ul li button,
.form-radio-flex,
.form-find {
  display: flex;
  display: -webkit-flex;
}

list-type-ulli,
.steps ul,
.actions ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

a:focus,
a:active {
  text-decoration: none;
  outline: none;
  transition: all 300ms ease 0s;
  -moz-transition: all 300ms ease 0s;
  -webkit-transition: all 300ms ease 0s;
  -o-transition: all 300ms ease 0s;
  -ms-transition: all 300ms ease 0s;
}

.clear {
  clear: both;
}

h2 {
  font-size: 30px;
  margin: 0px;
}


.main {
  padding: 50px 0;
  position: relative;
  z-index: 99;
}

.master-container {
  padding: 0px;
  border-radius: 5px;
  border-style: solid;
}

.ms-form-container {
  margin: 0 auto;
  background: rgb(240, 240, 240);
  padding: 0px;
  border: 2px solid rgb(220, 220, 220);
  border-radius: 5px;
}

fieldset {
  padding: 0px;
  margin: 0px;
  border: none;
  padding: 20px;
}

p.desc {
  margin: 0px;
  margin-bottom: 25px;
  color: #555;
}

.form-label {
  display: block;
  width: 100%;
  font-size: 0.8rem;
  font-weight: 600;
  color: rgb(100, 100, 100);
  margin-bottom: 5px;
}

.text-input {
  font-size: 1rem;
  color: #999;
  display: block;
  margin: 0px;
}

.searchGroupButton {
  background-color: rgb(100, 100, 100);
  padding: 20px;
  padding-top: 0px;
  padding-bottom: 0px;
  margin-left: 10px;
  border: none;
  border-radius: 5px;
  border: 1px solid rgb(140, 140, 140);
  cursor: pointer;
}

.searchGroupButtonText {
  font-size: 0.75rem;
  color: white;
  margin: 8px;
}

.text-input span {
  color: #222;
  font-weight: bold;
}

.form-flex {
  margin: 0 -10px;
}

.form-flex .form-group {
  width: 50%;
  padding: 0 10px;
}

.form-group,
.form-date {
  margin-bottom: 18px;
  position: relative;
}

.form-date-group {
  border: 1px solid transparent;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  -o-border-radius: 5px;
  -ms-border-radius: 5px;
}

.form-date-group select {
  border: 1px solid #ebebeb;
  width: 100%;
  box-sizing: border-box;
  appearance: none !important;
  -moz-appearance: none !important;
  -webkit-appearance: none !important;
  -o-appearance: none !important;
  -ms-appearance: none !important;
  position: relative;
  background: 0 0;
  z-index: 10;
  cursor: pointer;
  padding: 0 20px;
  height: 50px;
  font-size: 14px;
  color: #999;
  box-sizing: border-box;
  background-color: #fff;
  color: #222;
  font-weight: bold;
}

.form-date-item {
  position: relative;
  overflow: hidden;
  width: 100px;
  margin-right: 10px;
}

.vertical {
  display: block;
  width: 100%;
  overflow: hidden;
}

.vertical .steps {
  float: left;
  width: 250px;
}

.vertical .content,
.vertical .actions {
  float: right;
  width: 890px;
}

.content {
  min-height: 800px;
}

.title {
  align-items: center;
  -moz-align-items: center;
  -webkit-align-items: center;
  -o-align-items: center;
  -ms-align-items: center;
}

.title .step-number {
  width: 40px;
  height: 40px;
  align-items: center;
  -moz-align-items: center;
  -webkit-align-items: center;
  -o-align-items: center;
  -ms-align-items: center;
  justify-content: center;
  -moz-justify-content: center;
  -webkit-justify-content: center;
  -o-justify-content: center;
  -ms-justify-content: center;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  -o-border-radius: 50%;
  -ms-border-radius: 50%;
  background: #ebebeb;
  color: #999;
  margin-right: 15px;
  border: 5px solid #fff;
  font-weight: bold;
}

.title .step-text {
  font-weight: bold;
  color: #999;
}

.current .title .step-number {
  background: rgb(238, 129, 0);
  color: #fff;
  font-size: 1rem;
}

.current .title .step-text {
  color: rgb(238, 129, 0);
  font-size: 1rem;
}

.content h3 {
  display: none;
}

.content,
.actions {
  background: #f8f8f8;
}

.actions {
  padding-bottom: 90px;
}

.actions ul {
  padding-left: 45px;
  padding-right: 55px;
}

.actions ul .disabled {
  display: none;
}

.actions ul li {
  float: right;
}

.actions ul li:first-child {
  float: left;
}

.actions ul li:first-child a {
  background: rgb(200, 200, 200);
  color: rgb(150, 150, 150);
}

.actions ul li a {
  width: 140px;
  height: 50px;
  border-radius: 10px;
  color: #fff;
  background: rgb(238, 129, 0);
  align-items: center;
  -moz-align-items: center;
  -webkit-align-items: center;
  -o-align-items: center;
  -ms-align-items: center;
  justify-content: center;
  -moz-justify-content: center;
  -webkit-justify-content: center;
  -o-justify-content: center;
  -ms-justify-content: center;
  text-decoration: none;
}

.actions ul li button {
  width: 140px;
  height: 50px;
  border-radius: 10px;
  color: #fff;
  background: rgb(238, 129, 0);
  align-items: center;
  -moz-align-items: center;
  -webkit-align-items: center;
  -o-align-items: center;
  -ms-align-items: center;
  justify-content: center;
  -moz-justify-content: center;
  -webkit-justify-content: center;
  -o-justify-content: center;
  -ms-justify-content: center;
  text-decoration: none;
}

.form-radio-flex {
  flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -o-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  margin: 0 -15px;
}

.form-radio-flex .form-radio-item {
  padding: 0 15px;
  margin-bottom: 25px;
}

.form-radio-flex input {
  width: 0;
  height: 0;
  position: absolute;
  left: -9999px;
}

.form-radio-flex input+label {
  margin: 0px;
  width: 223px;
  height: 133px;
  box-sizing: border-box;
  position: relative;
  display: inline-block;
  text-align: center;
  background-color: transparent;
  border: 1px solid transparent;
  text-align: center;
  text-transform: none;
  transition: border-color .15s ease-out, color .25s ease-out, background-color .15s ease-out;
}

.form-radio-flex input+label img {
  width: 100%;
  height: 100%;
}

.form-radio-flex input:checked+label {
  border: 1px solid rgb(238, 129, 0);
  z-index: 1;
}

.form-radio-flex input:focus+label {
  outline: none;
}

.form-radio-flex input:hover {
  border: 1px solid rgb(238, 129, 0);
}

label.error {
  display: block;
  position: absolute;
  top: 0px;
  right: 0;
}

label.error:after {
  font-family: 'Material-Design-Iconic-Font';
  position: absolute;
  content: '\f135';
  right: 20px;
  top: 50px;
  font-size: 13px;
  color: #f63726;
}

#information-form input.error {
  border: 1px solid #f63726;
  color: #6e707e;
  font-size: 1rem;
  line-height: 1.5;
  width: 100%;
}

#search_client,
#search_agency,
#search_agent {
  margin-right: 20px;
  padding-left: 40px;
  padding-right: 40px;
}

.submit {
  width: 150px;
  background: #666;
  border: 0px;
  color: #fff;
  font-weight: 400;
  cursor: pointer;
  border-radius: 5px;
}

.submit:hover {
  background-color: #4d4d4d;
}


.form-icon {
  position: absolute;
  top: 10px;
  left: 15px;
  font-size: 18px;
  color: #999;
}

.labelWithInfoTooltip {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.info-tooltip {
  color: rgb(170, 170, 170);
  margin-left: 10px;
  cursor: pointer;
}

#secondaryClientToggleBtn {
  margin-bottom: 10px;
}

.formSectionHeader {
  margin-top: 20px;
}

.choose-bank-desc {
  color: #666;
  margin: 0px;
  padding-top: 30px;
  padding-bottom: 35px;
}

#slider-margin {
  height: 9px;
  border: none;
  /* box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  -o-box-shadow: none;
  -ms-box-shadow: none; */
  background: #e8e8e8;
  border-radius: 0px;
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
  -o-border-radius: 0px;
  -ms-border-radius: 0px;
  position: relative;
  margin-top: 110px;
}

#slider-margin .noUi-marker-horizontal.noUi-marker-large,
#slider-margin .noUi-marker-horizontal.noUi-marker {
  height: 0px;
}

#slider-margin .noUi-connect {
  background: rgb(238, 129, 0);
}

#slider-margin .noUi-connects {
  border-radius: 0px;
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
  -o-border-radius: 0px;
  -ms-border-radius: 0px;
}

#slider-margin .noUi-handle {
  width: 15px;
  height: 30px;
  top: -12px;
  background: #e8e8e8;
  outline: none;
  border: none;
  right: -15px;
  border: 1px solid rgb(238, 129, 0);
  border-radius: 0px;
}

#slider-margin .noUi-handle:after,
#slider-margin .noUi-handle:before {
  width: 0px;
}

#slider-margin .noUi-handle .noUi-tooltip {
  bottom: 33px;
  border: none;
  background: transparent;
  font-size: 16px;
  color: rgb(238, 129, 0);
  padding: 0px;
}

#slider-margin .noUi-pips {
  width: 96%;
}

#slider-margin .noUi-pips .noUi-value {
  top: -50px;
  font-size: 16px;
  color: #666;
}

#slider-margin .noUi-pips .noUi-value:before {
  content: '$';
}

#slider-margin .noUi-pips .noUi-value-horizontal {
  transform: none;
  -moz-transform: none;
  -webkit-transform: none;
  -o-transform: none;
  -ms-transform: none;
}

.your-money {
  font-size: 16px;
  color: #222;
  margin: 0px;
  padding-top: 62px;
}

.your-money .money {
  font-size: 28px;
  font-weight: bold;
}

#information-form .form-control {
  color: #222222;
}

@media screen and (max-width: 1024px) {
  .ms-form-container {
    width: calc(100% - 10px);
    max-width: 100%;
  }

  .vertical .steps,
  .vertical .content,
  .vertical .actions {
    float: none;
    width: 100%;
  }

  #search_client,
  #search_agency,
  #search_agent {
    width: 100%;
  }

  .form-radio-flex input+label {
    width: 190px;
    height: 120px;
  }
}

@media screen and (max-width: 992px) {
  .content {
    min-height: 900px;
  }

  .form-radio-flex input+label {
    width: 100px;
    height: 65px;
  }
}

@media screen and (max-width: 768px) {

  #steps-uid-0-p-0 .form-row,
  #steps-uid-0-p-0 .form-group,
  #steps-uid-0-p-0 .form-date {
    width: 100%;
  }

  .form-flex {
    flex-direction: column;
    -moz-flex-direction: column;
    -webkit-flex-direction: column;
    -o-flex-direction: column;
    -ms-flex-direction: column;
    margin: 0px;
  }

  .form-flex .form-group {
    padding: 0px;
  }

  fieldset,
  .actions ul {
    padding-left: 30px;
    padding-right: 30px;
  }

  #slider-margin .noUi-pips {
    width: 94%;
  }
}

@media screen and (max-width: 480px) {

  .form-date-group,
  .form-find {
    flex-direction: column;
    -moz-flex-direction: column;
    -webkit-flex-direction: column;
    -o-flex-direction: column;
    -ms-flex-direction: column;
  }

  .content {
    min-height: 1050px;
  }

  #search_client,
  #search_agency,
  #search_agent {
    margin-bottom: 20px;
  }

  .actions ul li a {
    width: 100px;
  }

  .actions ul li button {
    width: 100px;
  }

  .form-radio-flex {
    margin: 0 -5px;
  }

  .form-radio-flex .form-radio-item {
    padding: 0 5px;
  }

  .form-radio-flex input+label {
    width: 90px;
    height: 61px;
  }
}

.error {
  color: #5a5c69;
  font-size: 7rem;
  position: relative;
  line-height: 1;
  width: 12.5rem;
}


.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked+.slider {
  background-color: rgb(238, 129, 0);
}

/* input:focus + .slider {
  box-shadow: 0 0 0px rgb(238,129,0);
} */

input:checked+.slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}


.table-condensed>thead>tr>th,
.table-condensed>tbody>tr>th,
.table-condensed>tfoot>tr>th,
.table-condensed>thead>tr>td,
.table-condensed>tbody>tr>td,
.table-condensed>tfoot>tr>td {
  padding: 3px;
  vertical-align: middle;
  font-size: 12px;
}

.timeBtn {
  margin-bottom: 5px;
  margin-right: 5px;
  width: 70px;
  color: white;
}

.steps .title {
  cursor: pointer;
}

/* Rounded divider */
hr.rounded {
  border-top: 3px solid rgb(220, 220, 220);
  border-radius: 5px;
}

.formSectionHeader {
  font-size: 24px;
  font-weight: 500;
}

.formSubmitButton {
  padding: 10px;
  color: white;
  background-color: rgb(238, 129, 0);
  border-radius: 5px;
  border: none;

}




/*# sourceMappingURL=steppedForm.css.map */