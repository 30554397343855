  
.jobSheetTable td {           
    border-top: 1px solid rgb(160,160,160);
}     
.jobSheetTableRed td {           
    border-top: 1px solid rgb(160,160,160);
}   
.jobSheetTable4Columns td {           
    border-top: 1px solid rgb(160,160,160);
}   


.summaryHeader {
    font-size:8mm;
    color:var(--primary);
}

.statusTableMasterContainer {
    display:flex;
    flex-direction: row;
}

.jobStatusTable {
    width:97.5mm;
    text-align:center;
    margin-right:5mm;
    float: left;
} 

    .jobStatusTable th {
        width:97.5mm;
        background-color: var(--secondary);
    }
    
    .jobStatusTable td {   
        vertical-align:top;
        text-align:left;
    }
        .jobStatusTable td:first-child {   
            width: 40mm;
        }

    .jobStatusTable tr:last-child td:first-child {   
        border-bottom-left-radius: 2mm;
    }

    .jobStatusTable tr:last-child td:last-child {   
        border-bottom-right-radius: 2mm;
    }

.jobSheetTable {
    width:200mm;
    text-align:center;
} 

    .jobSheetTable th {
        width:200mm;
    }
    
    .jobSheetTable td {   
        vertical-align:top;
        text-align:left;
    }
        .jobSheetTable td:nth-child(1) {   
            background-color: rgb(220,220,220);
            width: 50mm;
        }
    .jobSheetTable tr:last-child td:first-child {   
        border-bottom-left-radius: 2mm;
    }

    .jobSheetTable tr:last-child td:last-child {   
        border-bottom-right-radius: 2mm;
    }

.jobSheetTableRed {
    width:97.5mm;
    text-align:center;
    margin-right:5mm;
} 

    .jobSheetTableRed th {
        background-color: var(--secondary);
    }
    
    .jobSheetTableRed td {   
        vertical-align:top;
        text-align:left;
    }
        .jobSheetTableRed td:nth-child(1) {   
            background-color: rgb(220,220,220);
            width: 40mm;
        }
    .jobSheetTableRed tr:last-child td:first-child {   
        border-bottom-left-radius: 2mm;
    }

    .jobSheetTableRed tr:last-child td:last-child {   
        border-bottom-right-radius: 2mm;
    }

.jobSheetTable4Columns {
    width:200mm;
    text-align:center;
} 

    .jobSheetTable4Columns th {
        width:200mm;
    }
    
    .jobSheetTable4Columns td {   
        vertical-align:top;
        text-align:left;
    }
        .jobSheetTable4Columns td:nth-child(1) {   
            background-color: rgb(220,220,220);
            width: 50mm;
        }
        .jobSheetTable4Columns td:nth-child(3) {   
            background-color: rgb(220,220,220);
            width: 50mm;
        }

    .jobSheetTable4Columns tr:last-child td:first-child {   
        border-bottom-left-radius: 2mm;
    }

    .jobSheetTable4Columns tr:last-child td:last-child {   
        border-bottom-right-radius: 2mm;
    }

.inspectionNotesTable {
    width:200mm;
    text-align:center;
} 

    .inspectionNotesTable th {
        width:200mm;
    }
    
    .inspectionNotesTable td {   
        vertical-align:top;
        text-align:left;
        height: 400px;
    }
    
    .inspectionNotesTable tr:last-child td:first-child {   
        background-color: white;
        border-bottom-left-radius: 2mm;
    }

    .inspectionNotesTable tr:last-child td:last-child {   
        border-bottom-right-radius: 2mm;
    }