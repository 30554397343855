@import url('https://fonts.googleapis.com/css?family=Open+Sans');


body {
  margin: 0;
  font-family: 'Open Sans', cursive;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font: 14px "Lucida Grande", Helvetica, Arial, sans-serif !important;
}

.pac-item {
  font-size: 1rem;
  ;
}

input {
  box-shadow: none !important;
}

.font div{
  font: 12px "Lucida Grande", Helvetica, Arial, sans-serif !important;
}
.font b{
  font: 12px "Lucida Grande", Helvetica, Arial, sans-serif !important;
}

.font button{
  font: 12px "Lucida Grande", Helvetica, Arial, sans-serif !important;
}
