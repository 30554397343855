.team-schedule-table {
	width: 100%;
	height: 70vh;
	overflow-x: scroll;
	overflow-y: scroll;
}



.team-schedule-table p {
	margin: 0;
}

.teamScheduleJobBtn{
	margin-bottom:20px; 
	width:120px;
}

.teamScheduleAppointmentBtn{
	margin-bottom:20px; 
	width:120px;
}

.scheduleTable tbody tr td {
	width: 140px;
	min-width: 140px;
	height: 80px;
	white-space: nowrap;
}
.scheduleTable thead tr th {
	width: 140px;
	min-width: 140px;
	background: rgb(60,60,60);
	color: white;
	font-weight: bold;
	border-bottom-width: 1px;
	white-space: nowrap;
	height: 100%;
	position: sticky;
	left: 0;
	z-index: 2;
	vertical-align: middle;
}
.scheduleTable thead th { position: sticky; top: 0; }

.scheduleTable thead tr th:first-child {
	width: 140px;
	min-width: 140px;
	background: rgb(60,60,60);
	color: white;
	font-weight: bold;
	border-bottom-width: 1px;
	white-space: nowrap;
	height: 100%;
	position: sticky;
	left: 0;
	z-index: 5;
	vertical-align: middle;
}
.scheduleTable tbody tr td:first-child {
	width: 140px;
	min-width: 140px;
	background: rgb(100,100,100);
	color: white;
	white-space: nowrap;
	height: 100%;
	position: sticky;
	left: 0;
	z-index: 2;
	vertical-align: middle;
}