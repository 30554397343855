
.portalTable {
    width:100%;
    font-size:0.75rem;
} 

    .portalTable th {
        font-weight:normal;
        background-color: var(--tableHeaders);     
        color: white;
        padding:1mm;
        border-radius:0mm;   
        text-align:left;
    }         

        .portalTable th:first-child {
            border-top-left-radius:2mm;    
        }
    
        .portalTable th:last-child {
            border-top-right-radius:2mm;    
        }
    
    .portalTable td {           
        font-weight:normal;
        background-color: rgb(240,240,240);     
        color: var(--primary);
        padding:1mm;
        border-radius:0mm;   
        text-align:left;
        border-top: 1px solid lightgrey;
    }   

        .portalTable td:first-child {
            text-align:left;
        }
    
            .portalTable tr:last-child td:first-child { 
                border-bottom-left-radius:2mm;    
            }
            
            .portalTable tr:last-child td:last-child { 
                border-bottom-right-radius:2mm;    
            }
    

/* svg  */

.checkmark__circle {
    stroke-dasharray: 166;
    stroke-dashoffset: 166;
    stroke-width: 2;
    stroke-miterlimit: 10;
    stroke: #7ac142;
    fill: none;
    animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
  }
  
  .checkmark {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    display: block;
    stroke-width: 2;
    stroke: #fff;
    stroke-miterlimit: 10;
    margin: 10% auto;
    box-shadow: inset 0px 0px 0px #7ac142;
    animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
  }
  
  .checkmark__check {
    transform-origin: 50% 50%;
    stroke-dasharray: 48;
    stroke-dashoffset: 48;
    animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
  }
  
  @keyframes stroke {
    100% {
      stroke-dashoffset: 0;
    }
  }
  @keyframes scale {
    0%, 100% {
      transform: none;
    }
    50% {
      transform: scale3d(1.1, 1.1, 1);
    }
  }
  @keyframes fill {
    100% {
      box-shadow: inset 0px 0px 0px 30px #7ac142;
    }
  }