@import url(https://fonts.googleapis.com/css?family=Open+Sans);

:root {
    --pageBackground: rgb(240,240,240);
    --primary: rgb(60,60,60);
    --secondary: rgb(238,30,20); 
    --headerBar: rgb(150,150,150);
    --footerBar: rgb(150,150,150);  
    --navBar: rgb(130,130,130);
    --navOn: rgb(255,255,255);
    --navOff: rgb(180,180,180);
    --sectionHeaders: rgb(60,60,60);
    --tableHeaders: rgb(130,130,130);
    --issueBoxBG: rgb(245,245,245);
    --issueAlert: rgb(238,30,20);
}

* {
   font-family: 'Open Sans',serif;
}

html, body {
    margin:0;
    padding:0;
    font: 14px "Lucida Grande", Helvetica, Arial, sans-serif;
    color: var(--primary);
}

p {
    color: var(--primary);
}


.sectionHeader {
    font-size:7mm;
    margin-bottom:2mm;
    font-weight: 600;
    color:var(--primary);
}

/*Default table styling*/
th {
    font-weight:normal;
    background-color: var(--tableHeaders);     
    color: white;
    padding:1mm;   
    font-size:4mm;
}
td {   
    background-color: white;     
    color: var(--primary);
    padding:1mm;
    font-size:4mm;
}
table {
    width:100%;
    font-size:0.75rem;
} 

    table th {
        font-weight:normal;
        background-color: var(--tableHeaders);     
        color: white;
        padding:1mm;
        border-radius:0mm;
        text-align: center;
    }         

        table th:first-child {
            border-top-left-radius:2mm;    
        }
    
        table th:last-child {
            border-top-right-radius:2mm;    
        }
    
    table td {           
        font-weight:normal;
        background-color: white;     
        color: var(--primary);
        padding:1mm;
        border-radius:0mm;   
        text-align:left;
    }   

    
        table tr:last-child td:first-child { 
            border-bottom-left-radius:2mm;    
        }
        
        table tr:last-child td:last-child { 
            border-bottom-right-radius:2mm;    
        }

textarea{ 
    padding:5px;
    border:dotted 2px var(--issueAlert);
    -webkit-border-radius: 10px; 
    -moz-border-radius: 10px; 
    border-radius: 10px;    
    outline: none !important;
     width: 100%;
}


.crackEditor { 
    padding:5px;
    border:dotted 2px var(--issueAlert);
    -webkit-border-radius: 10px; 
    -moz-border-radius: 10px; 
    border-radius: 10px;    
    outline: none !important;
}


.hide {
    display:none;
}

.a4Page {
    width:210mm;
    height:100vh; /*The window height will be exact A4 dimensions when PDF created. Instruction from restpack*/
    margin:0;
    padding:0;
    display:block;
}
.standardPage {
    background-color: var(--pageBackground);
    position: relative; /*Required so that footer bar (position absolute and bottom:0) stays at the bottom of the parent div*/
}

.pagePadding {
    padding:5mm;
}

.sectionPadding{
    padding:5mm;    
}

.pageTitle {
    font-size:6mm;
    margin-bottom:5mm;
    color: var(--primary);
}


.footerSection {    
    background-color: white;
    width:100%;
    position:absolute; /*Required so that footer bar (position absolute and bottom:0) stays at the bottom of the parent div*/
    bottom:0;  
}
    
    .footerSectionBar {    
        color:white;
        background-color: var(--footerBar);
        height:1.5mm;
        width:100%;
    }
    
    .footerSectionBarText {   
        vertical-align:bottom;
        color:var(--primary);   
        font-size:3mm;
        text-align:center;
        padding:1mm;
    }
    

.sectionTitleBar {
    color:white;
    background-color: rgb(238,30,20);
    text-align:center;
    line-height:8mm;
    font-size:4mm;
    height:8mm;
}

.sectionTitleItem {
    flex:1;
    text-align:left;
}

.sectionTitleItem {
    flex:1;
    text-align:left;
}

.biDivLeft {
    width: 95mm;
    margin-right: 5mm;
    float:left;
}

.biDivRight {
    width: 95mm;
    margin-left: 5mm;
    float:left;
}


.halfTableNoHeader {
    display:inline-block;
    margin-bottom: 5mm;
    width:95mm;
    font-size:4mm;
} 

    .halfTableNoHeader td {
        border-radius:2mm;
        height:6mm;    
        border: 1px solid #ddd;
        padding:1mm;
    }  
         
        .halfTableNoHeader td:nth-child(1) {
            color:white;
            background-color: var(--primary);
            text-align:right;
            width:40mm;
            padding-right:2mm;
        }        
                  
        .halfTableNoHeader td:nth-child(2) {
            text-align:center;
            background-color:white;
            width:55mm;
        }

.issueBox {
    background-color: var(--issueBoxBG);
    border: 2px dotted var(--secondary);
    border-radius:2mm;
    padding:5mm;
    margin:5mm;
}

.issueText {
    background-color: var(--issueAlert);
    color:white;
    border-radius:2mm;
    padding:1mm;
    text-align:center;
}




#coverPage {    
  background-image: url("https://res.cloudinary.com/djr4ppmrz/image/upload/v1592545996/inspectAPP/APP%20Media/coverBackground3.png");
  background-color: rgb(180,180,180);
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
  background-position: center; /* Center the image */
}

.coverContent {
    height:22cm;
    width:15cm;
    position:absolute;
    top:3cm;
    left:3cm;
    border-radius:0.3cm;
    background-color: white;
    z-index:2;
    text-align: center;
}

#coverLogo {
    margin-left:2cm;
    margin-right:2cm;
    margin-top:2cm;
    width:9cm;
}

#coverTitleJobType {
    color:var(--primary);
    margin-top:0.6cm;
    font-size: 0.8cm;
    font-weight: bold;
    z-index:2;
}
#coverTitleJobStage {
    color:var(--primary);
    font-size: 0.8cm;
    z-index:2;
}

.headerSection {
    background-color: var(--headerBar);
    height:20mm;
    width:100%;
    color:white;
}
    
    .headerSectionBar {    
        color:white;
        background-color: rgb(120,120,120);
        height:1mm;
        width:100%;
    }
    .headerSectionLeft {
        display:inline-block;
        height:20mm;
        width:40mm;
        vertical-align:top;
    }

        .headerLogo {
            vertical-align:center;
            display:inline-block;
            height:8mm;
            margin-left:3mm;
            margin-top:6mm;
        }
        
    .headerSectionCenter {
        display:inline-block;
        height:20mm;
        padding-top: 2mm;
        width:125mm;
        text-align:center;
        vertical-align: center;
    }
            .headerSectionCenterText {
                font-size:3mm;
                display: inline-block;
                vertical-align: middle;
                line-height: normal;
                margin:0mm;
                color: var(--primary);
            }

    .headerSectionRight {
        display:inline-block;
        height:20mm;
        width:40mm;
        vertical-align:top;
        text-align:right;
    }
    
        .pageNumber {
            display:inline-block;
            margin-top:8mm;
            font-size:4mm;
            color: var(--primary);
            text-align:right;            
        }

.bodySection {    
    display:block;
    background-color: transparent;
    height:262mm;
    width:100%;
    color: var(--primary);
}


.navBarSection {
    height:12mm;
    background-color:var(--navBar);
    display:flex;
    justify-content: center;
    width:100%;
    text-align:center;
}

.navBarItem {
    color:var(--primary);   
    width:48mm;
    margin:2mm;
    border-radius:2mm;
    padding:2mm;
    padding-top:1mm;
    padding-bottom:1mm;
    font-size:4mm;
}

.navOn {
    background-color:var(--navOn);
}

.navOff {
    background-color:var(--navOff);
}




.reportItemPhotoTable {
    width:100%;
    margin-top: 5mm;
    color: var(--tableHeaders);
    table-layout : fixed;
}
    
    .reportItemPhotoTable tr td:first-child {
        padding:1mm;
        border-radius:0mm;   
        border-bottom-left-radius:2mm;    
        font-size:4mm;
    }  
    
    .reportItemPhotoTable tr td:last-child {
        padding:1mm;
        border-radius:0mm;   
        border-bottom-right-radius:2mm;    
        font-size:4mm;
    }
    .reportItemPhotoTable td {
        border-top:none;
    }   
    
.reportIssuePhotoTable {
    width:100%;
    margin-top: 5mm;
    color: var(--primary);
}
    .reportIssuePhotoTable th {
        background-color: var(--issueAlert);
        color: white;
    }   
    .reportIssuePhotoTable td {
        width:95mm;
        height:60mm;
        text-align:center;
        border-top:none;
    }   
    
    .reportIssuePhotoTable tr td:first-child {
        padding:1mm;
        border-radius:0mm;   
        border-bottom-left-radius:2mm;    
        font-size:4mm;
    }  
    
    .reportIssuePhotoTable tr td:last-child {
        padding:1mm;
        border-radius:0mm;   
        border-bottom-right-radius:2mm;    
        font-size:4mm;
    }
    
.smallText {
    font-size:3mm;
}

.reportAdditionalIssuePhotoTable {
    width:100%;
    margin-top: 5mm;
    color: var(--primary);
    table-layout : fixed;
}
    
    .reportAdditionalIssuePhotoTable tr td:first-child {
        padding:1mm;
        border-radius:0mm;   
        border-bottom-left-radius:2mm;    
        font-size:4mm;
    }  
    
    .reportAdditionalIssuePhotoTable tr td:last-child {
        padding:1mm;
        border-radius:0mm;   
        border-bottom-right-radius:2mm;    
        font-size:4mm;
    }


.attributesBar {
    display:flex;
    flex-direction:row;
    padding-top:5mm;
}

.attribute {
    flex:1;
    padding-horizontal:1mm;
}

.attributeDoubleWidth {
    flex:2;
    padding-horizontal:1mm;    
}

.attributesTable {
    width:100%;
    color: var(--primary);
}
    .attributesTable td {
        text-align:center;
        padding:1mm;
        border-radius:0mm;   
        border-bottom-left-radius:2mm;    
        border-bottom-right-radius:2mm;    
        font-size:4mm;
    }   
    
.commentsTable {
    margin-top: 5mm;
    width:100%;
} 
    .commentsTable td {   
        vertical-align:top;
        text-align:left;
        border-radius:0mm;   
        border-bottom-left-radius:2mm;    
        border-bottom-right-radius:2mm;    
    }

    
.itemCommentsTable {
    margin-top: 5mm;
    width:100%;
} 
    .itemCommentsTable th {
        text-align:left;
        font-weight:normal;
        background-color: var(--tableHeaders);     
        color: white;
        padding:1mm;
        border-radius:0mm;   
        width:97.5mm;
    } 
        .itemCommentsTable th:first-child {
            border-top-left-radius:2mm;    
        }    
        .itemCommentsTable th:last-child {
            border-top-right-radius:2mm;    
        }

    .itemCommentsTable td {   
        vertical-align:top;
        text-align:left;
        border-radius:0mm;   
        border-bottom-left-radius:2mm;    
        border-bottom-right-radius:2mm;    
    }
    
.issueCommentsTable {
    margin-top: 5mm;
    width:100%;
} 
    .issueCommentsTable th {
        text-align:left;
        font-weight:normal;
        background-color: var(--issueAlert);     
        color: white;
        padding:1mm;
        border-radius:0mm;   
        width:97.5mm;
    } 
        .issueCommentsTable th:first-child {
            border-top-left-radius:2mm;    
        }    
        .issueCommentsTable th:last-child {
            border-top-right-radius:2mm;    
        }

    .issueCommentsTable td {   
        vertical-align:top;
        text-align:left;
        border-radius:0mm;   
        border-bottom-left-radius:2mm;    
        border-bottom-right-radius:2mm;    
    }
    
.reportSummary {
    display:inline-block;
    margin-top: 10mm;
    margin-left: 10mm;
    margin-right: 10mm;
    margin-bottom: 10mm;
}



    .reportSummaryTable tr {
        border-bottom: 1px solid #ddd;
    } 
    
    .reportSummaryTable td {
        padding:1mm;
        padding-top:2mm;
        padding-bottom:2mm;
    }  
         
        .reportSummaryTable td:nth-child(1) {
            color:white;
            background-color: var(--tableHeaders);
            text-align:right;
            width:20%;
        }        
                  
        .reportSummaryTable td:nth-child(2) {
            background-color:white;
            width:80%;
        }
        

#approverSignature {
    height:20mm;
}

.scope {
    display:inline-block;
    margin-top: 10mm;
    margin-left: 10mm;
    margin-right: 10mm;
    margin-bottom: 10mm;
}
.scopeHeader {
    font-size:6mm;
    margin-bottom:2mm;
    color:var(--primary);
}


.scopeBodySection {
    color:white;
    width: 200mm;
    margin-top: 10mm;
    margin-left: 10mm;
    margin-right: 10mm;
    margin-bottom: 10mm;
}



    
.propertySummary {
    display:inline-block;
    margin-top: 5mm;
    margin-left: 5mm;
    margin-right: 5mm;
    margin-bottom: 5mm;
}

.propertySummaryHeader {
    font-size:6mm;
    color:var(--primary);
}

.propertyDescriptionTable {
    margin-top: 5mm;
    width:200mm;
    text-align:center;
} 

    .propertyDescriptionTable th {
        width:200mm;
    }
    
    .propertyDescriptionTable td {   
        height:50mm;
        vertical-align:top;
        text-align:left;
        border-bottom-left-radius:2mm; 
        border-bottom-right-radius:2mm;    
    }

#propertyDescription {
    display:flex;
    flex:1;
    height:100%;
}

.stretchTextItem {
    display:flex;
    flex:1;
    height:100%;    
}
    
.propertyImageTable {
    margin-top: 5mm;
    width:200mm;
    text-align:center;
} 

    .propertyImageTable th {
        width:200mm;
    }
    
    .propertyImageTable td {   
        max-height:150mm;
        padding:2.5mm;        
        text-align:center;
        justify-content:center;
    }
    
    .propertyImageTable tr:last-child td:first-child {   
        border-bottom-left-radius:2mm; 
    }
    
    .propertyImageTable tr:last-child td:last-child {   
        border-bottom-right-radius:2mm;    
    }


:nth-last-child(2)

.propertyImage {
    width:190mm;
    max-height:140mm;
    border-radius:3mm;
}


.propertyOverviewImage {
    max-width: 100%;
    max-width: 100%;
    border-radius:3mm;
}


.cellImageItem {    
     max-width: 180px;
     max-height: 100px;
     border-radius:1mm;
     text-align:center;
}

.cellImageIssue {    
     max-width: 360px;
     max-height: 80mm;
     border-radius:2mm;
     text-align:center;
}

.propertySummaryTableTop tr {
    margin-bottom:5mm;
} 

.propertySummaryTableTop td {
    border-radius:2mm;
    height:6mm;    
    font-size:4mm;
    border: 1px solid #ddd;
    padding:1mm;
}  
     
    .propertySummaryTableTop td:nth-child(1) {
        color:white;
        background-color: var(--primary);
        text-align:right;
        width:95mm;
    }        
              
    .propertySummaryTableTop td:nth-child(2) {
        background-color:white;
        width:30mm;
    }

.propertySummaryBiDivHeader {
    font-size:6mm; 
    text-align:center;    
}

.propertySummaryBiDivExternal {
    text-align:center;
    padding-top:5mm;
    width: 95mm;
    float:left;
}

.propertySummaryBiDivInternal {
    text-align:center;
    padding-top:5mm;
    width: 95mm;
    margin-left: 5mm;
    float:left;
}

.propertySummaryTableItems {
    display:inline-block;
    font-size:4mm;
    width:100%;
} 

    .propertySummaryTableItems th {
        font-weight:normal;
        background-color: var(--tableHeaders);     
        color: white;
        padding:1mm;
        border-radius:0mm;   
    }         

        .propertySummaryTableItems th:first-child {
            border-top-left-radius:2mm;    
            width:100mm;
        }
    
        .propertySummaryTableItems th:last-child {
            border-top-right-radius:2mm;    
            width:100mm;
        }
    
    .propertySummaryTableItems td {           
        font-weight:normal;
        background-color: white;     
        color: var(--primary);
        padding:1mm;
        border-radius:0mm;   
            text-align:left;
    }   

        .propertySummaryTableItems td:first-child {
            text-align:left;
        }
    
            .propertySummaryTableItems tr:last-child td:first-child { 
                border-bottom-left-radius:2mm;    
            }
            
            .propertySummaryTableItems tr:last-child td:last-child { 
                border-bottom-right-radius:2mm;    
            }


.propertySummaryDilapidationTableItems {
    display:inline-block;
    width:100%;
} 

    .propertySummaryDilapidationTableItems th {
        font-weight:normal;
        background-color: var(--tableHeaders);     
        color: white;
        padding:1mm;
        border-radius:0mm;   
    }         

        .propertySummaryDilapidationTableItems th:first-child {
            border-top-left-radius:2mm;    
            width:305px;
        }
    
        .propertySummaryDilapidationTableItems th:last-child {
            border-top-right-radius:2mm;    
            width:75px;
        }
    
    .propertySummaryDilapidationTableItems td {           
        font-weight:normal;
        background-color: white;     
        color: var(--primary);
        padding:1mm;
        border-radius:0mm;   
            text-align:center;
    }   

        .propertySummaryDilapidationTableItems td:first-child {
            text-align:left;
        }
    
            .propertySummaryDilapidationTableItems tr:last-child td:first-child { 
                border-bottom-left-radius:2mm;    
            }
            
            .propertySummaryDilapidationTableItems tr:last-child td:last-child { 
                border-bottom-right-radius:2mm;    
            }
        
    
.propertyOverviewPhotosTable {
    margin-top: 5mm;
    width:200mm;
    text-align:center;
} 

    .propertyOverviewPhotosTable th {
        width:200mm;
    }
    
    .propertyOverviewPhotosTable td {   
        height:40mm;
        padding:2mm;                
        width: 60mm;   
        border-top:none; 
    }
    
    .propertyOverviewPhotosTable tr:last-child td:first-child {   
        border-bottom-left-radius:2mm; 
    }
    
    .propertyOverviewPhotosTable tr:last-child td:last-child {   
        border-bottom-right-radius:2mm;    
    }
    
                  
.itemTitleBar {
    color:white;
    background-color: rgb(120,120,120);
    text-align:left;
    line-height:10mm;
    font-size:6mm;
    padding-left:5mm;
    height:10mm;
}



.itemSummaryDivs {
    display:flex;
    flex-direction:row;
}

.itemSummaryBiDivLeft {
    flex:1;
    padding-right:2.5mm; 
}
.itemSummaryBiDivRight {
    flex:1;
    padding-left:2.5mm; 
}





.itemSummaryTable {
    display:inline-block;
    font-size:4mm;
} 
    .itemSummaryTable th {
        font-weight:normal;
        background-color: var(--tableHeaders);     
        color: white;
        padding:1mm;
        border-radius:0mm;   
        width:97.5mm;
    } 
        .itemSummaryTable th:first-child {
            border-top-left-radius:2mm;    
        }    
        .itemSummaryTable th:last-child {
            border-top-right-radius:2mm;    
        }

    .itemSummaryTable td {
        vertical-align:top;
        text-align:left;
        border-radius:0mm;   
        border-bottom-left-radius:2mm;    
        border-bottom-right-radius:2mm;   
        width:97.5mm;
    }  
    

.itemSummaryIssueAlertTable {
    display:inline-block;
    font-size:4mm;
} 
    .itemSummaryIssueAlertTable th {
        font-weight:normal;
        background-color: var(--issueAlert);     
        color: white;
        padding:1mm;
        border-radius:0mm;   
        width:97.5mm;
    } 
        .itemSummaryIssueAlertTable th:first-child {
            border-top-left-radius:2mm;    
        }    
        .itemSummaryIssueAlertTable th:last-child {
            border-top-right-radius:2mm;    
        }

    .itemSummaryIssueAlertTable td {
        vertical-align:top;
        text-align:left;
        border-radius:0mm;   
        border-bottom-left-radius:2mm;    
        border-bottom-right-radius:2mm;   
        width:97.5mm;
    }  

    
.issueSummaryTable {
    display:inline-block;
    font-size:4mm;
} 
    .issueSummaryTable th {
        font-weight:normal;
        background-color: var(--issueAlert);     
        color: white;
        padding:1mm;
        border-radius:0mm;   
        width:97.5mm;
    } 
        .issueSummaryTable th:first-child {
            border-top-left-radius:2mm;    
        }    
        .issueSummaryTable th:last-child {
            border-top-right-radius:2mm;    
        }

    .issueSummaryTable td {
        vertical-align:top;
        text-align:left;
        border-radius:0mm;   
        border-bottom-left-radius:2mm;    
        border-bottom-right-radius:2mm;   
        width:97.5mm;
    }  

  
    
.issueSummaryTableCrack {
    display:inline-block;
    font-size:4mm;
} 
    .issueSummaryTableCrack th {
        font-weight:normal;
        background-color: var(--issueAlert);     
        color: white;
        padding:1mm;
        border-radius:0mm;   
        width:97.5mm;
    } 
        .issueSummaryTableCrack th:first-child {
            border-top-left-radius:2mm;    
        }    
        .issueSummaryTableCrack th:last-child {
            border-top-right-radius:2mm;    
        }

    .issueSummaryTableCrack td {
        vertical-align:top;
        text-align:left;
        border-radius:0mm;   
        border-bottom-left-radius:2mm;    
        border-bottom-right-radius:2mm;   
        width:97.5mm;
        height:110px;
    }  

    
  
    
.issueSummaryTableCrackReinspection {
    display:inline-block;
    font-size:4mm;
} 
    .issueSummaryTableCrackReinspection th {
        font-weight:normal;
        background-color: var(--issueAlert);     
        color: white;
        padding:1mm;
        border-radius:0mm;   
        width:97.5mm;
    } 
        .issueSummaryTableCrackReinspection th:first-child {
            border-top-left-radius:2mm;    
        }    
        .issueSummaryTableCrackReinspection th:last-child {
            border-top-right-radius:2mm;    
        }

    .issueSummaryTableCrackReinspection td {
        vertical-align:top;
        text-align:left;
        border-radius:0mm;   
        border-bottom-left-radius:2mm;    
        border-bottom-right-radius:2mm;   
        width:97.5mm;
        height:50px;
    }  

.issueSummaryTableBottom {
    display:inline-block;
    font-size:4mm;
    margin-top: 5mm;
} 
    .issueSummaryTableBottom th {
        font-weight:normal;
        background-color: var(--issueAlert);     
        color: white;
        padding:1mm;
        border-radius:0mm;   
        width:97.5mm;
    } 
        .issueSummaryTableBottom th:first-child {
            border-top-left-radius:2mm;    
        }    
        .issueSummaryTableBottom th:last-child {
            border-top-right-radius:2mm;    
        }

    .issueSummaryTableBottom td {
        vertical-align:top;
        text-align:left;
        border-radius:0mm;   
        border-bottom-left-radius:2mm;    
        border-bottom-right-radius:2mm;   
        width:97.5mm;
    }     

.noIssueAlertTable {
    display:inline-block;
    font-size:4mm;
} 
    .noIssueAlertTable th {
        font-weight:normal;
        background-color: rgb(150,150,150);   
        color: white;
        padding:1mm;
        border-radius:2mm;   
        width:97.5mm;
    } 

.issueAlertTable {
    display:inline-block;
    font-size:4mm;
} 
    .issueAlertTable th {
        font-weight:normal;
        background-color: rgb(238,30,20);
        color: white;
        padding:1mm;
        border-radius:2mm;   
        width:97.5mm;
    } 




.noDefectsAlertTable {  
    display:inline-block;
    width:95mm;
}
    .noDefectsAlertTable td {  
        display:inline-block;
        width:95mm;
        height:5mm;
        text-align:center;
        background-color: rgb(150,150,150);
        color:white;
    }
    
.issuesAlertTable {  
    display:inline-block;
    width:95mm;
}
    .issuesAlertTable td {  
        display:inline-block;
        width:95mm;
        height:5mm;
        text-align:center;
        background-color: rgb(238,30,20);
        color:white;
    }

.issueSummaryTableAreaPage {
    display:inline-block;
    margin-top: 5mm;
    margin-left:5mm;
    margin-right:5mm;
    font-size:4mm;
    font-weight:300;
} 
    
    .issueSummaryTableAreaPage th {
        color:white;
        background-color: var(--primary);
        text-align:center;
        width:50mm;
        border-radius:2mm;
        font-weight:300;
        padding:1mm;
    }  
    
    .issueSummaryTableAreaPage td {
        border-radius:2mm;
        border: 1px solid #ddd;
        padding:1mm;
    }  
     
        .issueSummaryTableAreaPage td:nth-child(1) {
            color:white;
            background-color: rgb(150,150,150);
            text-align:center;
            width:50mm;
        }        
                  
        .issueSummaryTableAreaPage td:nth-child(2) {
            text-align:center;
            background-color:white;
            width:140mm;
        }





.maintenancePlanSummaryTable {
    display:inline-block;
    margin-top: 4mm;
    width: 100%;
}
    .maintenancePlanSummaryTable tr {
        border-bottom: 1px solid #ddd;
    } 
    
    .maintenancePlanSummaryTable td {
        padding:1mm;
        font-size:12px;
        width:33.3mm;
    }  
            
        .maintenancePlanSummaryTable td:nth-child(1) {
            color:black;
            background-color: rgb(200,200,200);
            text-align:center;
        }       
            
                    
        .maintenancePlanSummaryTable td:nth-child(2) {
        }


.maintenancePlanTable {
    display:inline-block;
    margin-top: 4mm;
    width: 100%;
}
    .maintenancePlanTable tr {
        border-bottom: 1px solid #ddd;
    } 
    
    .maintenancePlanTable td {
        padding:1mm;
        font-size:12px;
        text-align:right;
    }  
            
        .maintenancePlanTable td:nth-child(1) {
            text-align: left;
            width: 300px;
        }    
            

    .maintenancePlanTable th {
        padding:1mm;
        font-size:12px;
        width:75px;
    }  
            
        .maintenancePlanTable th:nth-child(1) {
            color:white;
            background-color: var(--tableHeaders);
            text-align:center;
        }     

.maintenanceBarChartContainer {
    width: 755.9055px;
    height: 400px;
}